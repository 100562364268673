import React, { Component, Fragment } from "react";

import withStyles from "@material-ui/core/styles/withStyles";

const styles = (theme) => ({
  ...theme.spreadThis,
});

class Footer extends Component {
  render() {
    return (
      <Fragment>
        <footer id="footer">
          <p>
            <a href="/">Home</a>&nbsp;|&nbsp;<a href="/blog">Blog</a>&nbsp;
            |&nbsp;
            <a href="/contact">Contact</a>
          </p>
          <p>© Bani Creations 2025. All Rights Reserved.</p>
        </footer>
      </Fragment>
    );
  }
}

export default withStyles(styles)(Footer);
